import React, { useContext } from 'react'
import { IdiomaContext } from '../../context/IdiomaContext'
import { Link } from 'react-router-dom';

export const NavBar = () => {

    const { idioma } = useContext(IdiomaContext)

    const closeMenu = () => {
        const nav = document.querySelector('.navbar-collapse')
        nav.classList.remove('show')
        };

    const handleSubmit = (e) => {
        e.preventDefault();
        const proyecto = document.querySelector('.form-control').value;
        if (proyecto === 'Oftaco' || proyecto === 'oftaco') {
            window.location.href = '/proyecto_oftaco';
        } 
        if (proyecto === 'mercati' || proyecto === 'Mercati') {
            window.location.href = '/proyecto_mercati';
        } else {
            window.location.href = '/proyectos';
        }
    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <div className="container-fluid  justify-content-end">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse flex-grow-0" id="mynavbar">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item mx-3 fs-3">
                            <Link className="nav-link text-light" onClick={closeMenu} to="/">{idioma ? 'Inicio' : 'Home'}</Link>
                        </li>
                        <li className="nav-item mx-3 fs-3">
                            <Link className="nav-link text-light" onClick={closeMenu} to="/proyectos">{idioma ? 'Proyectos' : 'Projects'}</Link>
                        </li>
                        <li className="nav-item mx-3 fs-3">
                            <a className="nav-link text-light" href="imagenes/Profile.pdf" target="_blank"
                            >{idioma ? 'Descargar CV' : 'Download CV'}</a>
                        </li>
                    </ul>
                    <form className="d-flex">
                        <input className="form-control me-2" type="text" placeholder={idioma ? 'Tu proyecto' : 'Your Proyects'} aria-label="Search"
                            id="proyecto"/>
                        <button className="btn btn-primary" onClick={handleSubmit}>{idioma ? 'Ver' : 'See'}</button>
                    </form>
                </div>
            </div>
        </nav>
    )
}

