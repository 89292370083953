import  { useContext } from 'react'
import './Estudios.css'
import { IdiomaContext } from '../../context/IdiomaContext'

export const Estudios = () => {
    
    const { idioma } = useContext(IdiomaContext)

    return (
        <section>
            <div className="container text-dark">
                <div className="row justify-content-around">
                    <div className="col-12 col-md-6 col-xl-3 my-3">
                        <div className="estudio estudio-1 shadow m-2 correccion imgefecto">
                            <div className="row">
                                <div className="text-center col-xs-12">
                                    <h4 className="text-uppercase p-4">Frameworks</h4>
                                </div>
                                <div className="text-center m-2">
                                    <p>Bootstrap</p>
                                    <p>Laravel</p>
                                    <p>Express.js</p>
                                    <p>React.js</p>
                                    <p>Node.js</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 my-3">
                        <div className="estudio estudio-1 shadow m-2 correccion imgefecto">
                            <div className="row">
                                <div className="text-center col-xs-12">
                                    <h4 className="text-uppercase p-4" >{ idioma?'Lenguajes':'Language'}</h4>
                                </div>
                                <div className="text-center m-2">
                                    <p>Html</p>
                                    <p>css</p>
                                    <p>JavaScript</p>
                                    <p>PHP</p>
                                    <p>Java</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 my-3">
                        <div className="estudio estudio-1 shadow m-2 correccion imgefecto">
                            <div className="row">
                                <div className="text-center col-xs-12">
                                    <h4 className="text-uppercase p-4">{ idioma?'Bases de datos':'Databases'}</h4>
                                </div>
                                <div className="text-center m-2">
                                    <p>SQL</p>
                                    <p>MYSQL</p>
                                    <p>MongoDB</p>
                                    <p>Firebase</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 my-3">
                        <div className="estudio estudio-1 shadow m-2 correccion imgefecto">
                            <div className="row">
                                <div className="text-center col-xs-12">
                                    <h4 className="text-uppercase p-4">{ idioma?'Otros':'Others'}</h4>
                                </div>
                                <div className="text-center m-2">
                                    <p>SEO</p>
                                    <p>MVC</p>
                                    <p>POO</p>
                                    <p>Nginx</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
