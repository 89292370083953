import React, { useContext, useState } from 'react'
import './Portada.css'

import { ModoContext } from '../../context/ModoContext'
import { IdiomaContext } from '../../context/IdiomaContext'

export const Portada = () => {

    const { modo } = useContext(ModoContext)
    const { idioma } = useContext(IdiomaContext)
    const [image, setImage] = useState('imagenes/portada/principal.png')

    const salida = () => {
        setImage('imagenes/portada/principal.png')
    }

    const handleMouseMove = (e) => {
        const mouseY = e.clientY
        const mouseX = e.clientX
        const imageRect = e.target.getBoundingClientRect()
        const imageMiddleY = imageRect.top + imageRect.height / 2
        const imageMiddleX = imageRect.left + imageRect.width / 2

        if (mouseY < imageMiddleY) {
            if (mouseX < imageMiddleX) {
                setImage('imagenes/portada/a.png')
            } else {
                setImage('imagenes/portada/b.png')
            }
        } else {
            if (mouseX < imageMiddleX) {
                setImage('imagenes/portada/c.png')
            } else {
                setImage('imagenes/portada/d.png')
            }
        }
    }

    return (
        <section className={`container ${modo ? 'text-dark' : 'text-light'}`}  >
            <div className="row p-4 mb-2 p-md-5 ">
                <div className="col-12 col-md-4 cursor-imagen">
                    <img src={image} className="img-fluid" alt="" onMouseMove={handleMouseMove} onMouseOut={salida} />
                </div>
                <div className="col-12 col-md-8 align-self-center my-3 text-center text-md-start titulo">
                    <h1 className='cursor-texto'><b>Maximiliano Caprioglio</b></h1>
                    <h2 className='cursor-texto'>{idioma ? 'Desarrollador Web' : 'Web developer'}</h2>
                </div>
            </div>
            <div className="row my-2 mx-4 parrafo">
                <div className="col-12 text-center">
                    <p className='fs-2 cursor-texto'>{idioma ? '"...Cada nuevo proyecto me lleva a explorar, investigar y cuestionar temas tan diversos como apasionantes..."' : '"...Every new project leads me to discover, explore, and question such diverse and intriguing topics..."'}</p>
                </div>
            </div>
        </section>
    )
}
