import { createContext, useState } from 'react'

export const ModoContext = createContext()

export const ModoProvider = ({ children }) => {

    const [modo, setModo] = useState(true)

    const cambiarModo = () => {
        setModo(!modo)
    }

    return (
        <ModoContext.Provider value={{modo, cambiarModo}}>
            {children}
        </ModoContext.Provider>
    )
}
