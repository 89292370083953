import { useContext } from 'react'
import { Certificados } from '../Certificados/Certificados'
import { Estudios } from '../Estudios/Estudios'
import { Portada } from '../Portada/Portada'
import { ModoContext } from '../../context/ModoContext'

export const Inicio = () => {

    const { modo } = useContext(ModoContext)

    return (
        <div className={`${modo ? 'bg-light' : 'bg-secondary'}`} >
            <Portada />
            <Estudios />
            <Certificados />
        </div >
    )
}
