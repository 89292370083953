import { createContext, useState } from 'react'

export const IdiomaContext = createContext()

export const IdiomaProvider = ({ children }) => {

    const [idioma, setIdioma] = useState(true)

    const cambiarEs = () => {
        setIdioma(true)
    }

    const cambiarEn = () => {
        setIdioma(false)
    }

    return (
        <IdiomaContext.Provider value={{idioma, cambiarEs, cambiarEn}}>
            {children}
        </IdiomaContext.Provider>
    )
}
