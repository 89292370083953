import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export const Tabla = (info) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Historicos del dolar',
            },
        },
    };
    const labels = info.dia;
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Dolar Blue',
                data: info.valorA,
                borderColor: 'rgb(40, 117, 22)',
                backgroundColor: 'rgba(40, 117, 22, 0.5)',
            }, {
                fill: true,
                label: 'Dolar Oficial',
                data: info.valorB,
                borderColor: 'rgb(42, 213, 0)',
                backgroundColor: 'rgba(42, 213, 0, 0.5)',
            }
        ],
    };
    return (
        <div className="w-100" >
            <Line options={options} data={data} />
        </div>
    )
}
