import { useContext } from 'react'
import './Certificados.css'
import { ModoContext } from '../../context/ModoContext'
import { IdiomaContext } from '../../context/IdiomaContext'

export const Certificados = () => {

    const { modo } = useContext(ModoContext)
    const { idioma } = useContext(IdiomaContext)

    return (
        <section>
            <div className="container">
                <div className="row justify-content-around">
                    <div className="col-12 mt-4">
                        <div className="container imgefecto">
                            <div className="d-flex justify-content-center my-4">
                                <div className={`d-flex ${modo ? 'bg-dark' : 'bg-light'}`}>
                                    <div className={`vertical-text v-center ${modo ? 'text-light' : 'text-dark'} text-uppercase`}>
                                        <h3>{idioma ? 'Certificados' : 'CERTIFICATES'}</h3>
                                    </div>
                                </div>
                                <div className="d-flex flex-column ms-1">
                                    <a href="/imagenes/Certificados/Certificado-Java-Spring-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>Java Spring</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-PHP-MVC-Laravel-EducaciónIT.png"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>PHP MVC Laravel</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Wordpress-EducaciónIT.pdf"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>Wordpress</p>
                                    </a>
                                    <a href="/imagenes/Certificados/certificado-Programacion-Backend.png"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Carrera de Desarrollo Full Stack' : 'Full Stack Web Development'}</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Programación-Web-en-PHP-y-MySQL-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Programación Web en PHP y MySQL' : 'Web Programming in PHP and MySQL'}</p>
                                    </a>
                                    <a href="/imagenes/Certificados/certificado-React-Js.png" className="btn text-start"
                                        target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Carrera de Desarrollo Frontend React' : 'React Web Development'}</p>
                                    </a>
                                    <a href="/imagenes/Certificados/certificado-JavaScript.png" className="btn text-start"
                                        target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>JavaScript</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Java-para-no-programadores-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Java para no programadores' : 'Java for non-programmers'}</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Fundamentos-de-Programación-con-SQL-Server-2019-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Fundamentos de Programación con SQL Server 2019' : 'Fundamentals of Programming with SQL Server 2019'}
                                        </p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Introducción-a-Bases-de-Datos-y-SQL-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Introducción a Bases de Datos y SQL' : 'Introduction to Databases and SQL'}</p>
                                    </a>
                                    <a href="/imagenes/Certificados/Certificado-Introducción-al-Paradigma-de-Objetos-EducaciónIT.jpeg"
                                        className="btn text-start" target="_blank">
                                        <p className={`mb-0 ${modo ? 'text-dark' : 'text-light'}`}>{idioma ? 'Introducción al Paradigma de Objetos' : 'Introduction to Object-Oriented Programming'}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
