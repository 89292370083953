import  { useContext }from 'react'
import { Proyecto } from '../Proyecto/Proyecto'
import { ModoContext } from '../../context/ModoContext'

export const Proyectos = () => {
  
  const { modo } = useContext(ModoContext)

  return (
    <div className={`${modo ? 'bg-light' : 'bg-secondary'}`}>
      <Proyecto />
    </div>
  )
}
