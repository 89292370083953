import { useContext } from 'react'
import { BsLinkedin, BsFillEnvelopeFill, BsWhatsapp, BsGithub, BsGlobe } from "react-icons/bs"
import { FiSun, FiMoon, FiCircle, } from 'react-icons/fi'
import { ModoContext } from '../../context/ModoContext'
import { IdiomaContext } from '../../context/IdiomaContext'

export const Footer = () => {

    const { modo, cambiarModo } = useContext(ModoContext)
    const { idioma, cambiarEs, cambiarEn } = useContext(IdiomaContext)

    return (

        <footer className="bg-dark">
            <div className="d-flex justify-content-center">
                <div className="m-3">
                    <a className="text-Primary" href="https://www.linkedin.com/in/maximiliano-caprioglio/" target="_blank"
                        rel="noopener noreferrer">
                        <BsLinkedin />
                    </a>
                </div>
                <div className="m-3">
                    <a className="text-warning" href="mailto:maxicaprioglio@gmail.com" target="_blank"
                        rel="noopener noreferrer">
                        <BsFillEnvelopeFill />
                    </a>
                </div>
                <div className="m-3">
                    <a className="text-success" href="https://wa.me/+5491157194012" target="_blank"
                        rel="noopener noreferrer">
                        <BsWhatsapp />
                    </a>
                </div>
                <div className="m-3">
                    <a className="text-light" href="https://github.com/maxicaprioglio" target="_blank"
                        rel="noopener noreferrer">
                        <BsGithub />
                    </a>
                </div>
            </div>

            <div className="w-100 text-center bottom-0 start-0 mb-2">
                <div className="mb-1">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="mb-1 text-light">{idioma ? 'Modo' : 'Mode'}</div>
                        <button className="ms-4 badge rounded-pill bg-light text-dark p-0"
                            onClick={cambiarModo}>
                            <div className="d-flex justify-content-center align-items-center">
                                {modo ? <FiCircle className='m-1' /> : <FiSun className='m-1' />}
                                {modo ? <FiMoon className='m-1' /> : <FiCircle className='m-1' />}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-100 text-light text-center bottom-0 start-0 mb-2">
                <div className="text-light mb-1">
                    <div className="d-flex  justify-content-center  align-items-center">
                        <BsGlobe />
                        <div className="btn btn-dark" onClick={cambiarEn}>
                            EN
                        </div>
                        <div>/</div>
                        <div className="btn btn-dark" onClick={cambiarEs}>
                            ES
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 text-light text-center pb-3" >
                © 2023 Copyright
            </div>

        </footer>
    )
}
