import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NavBar } from './components/NavBar/NavBar'
import { Footer } from './components/Footer/Footer'
import { Inicio } from './components/Rutas/Inicio'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './App.css'
import { ModoProvider } from './context/ModoContext'
import { IdiomaProvider } from './context/IdiomaContext'
import { Proyectos } from './components/Rutas/Proyectos'

function App() {

  return (
    <IdiomaProvider>
      <ModoProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/proyectos" element={<Proyectos />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ModoProvider>
    </IdiomaProvider>
  );
}

export default App;
