import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { IdiomaContext } from '../../context/IdiomaContext'
import { ModoContext } from '../../context/ModoContext'
import { Cotizador } from './Cotizador/Cotizador'
import ReactPlayer from 'react-player'
import { BsGithub } from "react-icons/bs"


export const Proyecto = () => {

    const { idioma } = useContext(IdiomaContext)
    const { modo } = useContext(ModoContext)
    const [showModalOftaco, setShowModalOftaco] = useState(false)
    const [showModalCarfi, setShowModalCarfi] = useState(false)
    const [showModalJdmserv, setShowModalJdmserv] = useState(false)
    const [showModalNameApp, setShowModalNameApp] = useState(false)
    const [showModalVideo, setShowModalVideo] = useState(false)
    const [showModalBuka, setShowModalBuka] = useState(false)
    const [showModalClip, setShowModalClip] = useState(false)
    const [showModalCasting, setShowModalCasting] = useState(false)
    const [showModalReel, setShowModalReel] = useState(false)

    return (
        <section className="container">
            <div className={`py-4 ${modo ? 'text-dark' : 'text-light'}`}>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='/imagenes/laptop/oftaco.png'
                            className='img-fluid mb-3'
                            onClick={() => setShowModalOftaco(true)}
                            alt=""
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'Un proyecto web estático para una empresa contructora. Realizado con html, css, js y bootstrap.' : 'A static web project for a construction company. Built with HTML, CSS, JS, and Bootstrap.'}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-11 d-flex justify-content-center">
                                <button className="btn rounded-pill btn-primary me-2" onClick={() => setShowModalOftaco(true)}>{idioma ? 'Vista previa' : 'Preview'}</button>
                                <a type="button" className="btn rounded-pill btn-primary ms-2" href="https://www.oftaco.com"
                                    target="_blank" rel="noreferrer">{idioma ? 'Ir al sitio' : 'Go to the website'}</a>
                            </div>
                            <Modal
                                show={showModalOftaco}
                                onHide={() => setShowModalOftaco(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <Image
                                        src='/imagenes/proyectos/oftaco1.png'
                                        className='img-fluid mb-3'
                                        alt="" />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalOftaco(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='/imagenes/laptop/carfi.png'
                            className='img-fluid mb-3'
                            onClick={() => setShowModalCarfi(true)}
                            alt=""
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'Un proyecto web estático para una empresa de logística. Realizado con HTML, CSS, JS y Bootstrap.' : 'A static web project for a logistics company. Created with HTML, CSS, JS, and Bootstrap.'}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-11 d-flex justify-content-center">
                                <button className="btn rounded-pill btn-primary me-2" onClick={() => setShowModalCarfi(true)}>{idioma ? 'Vista previa' : 'Preview'}</button>
                                <a type="button" className="btn rounded-pill btn-primary ms-2"
                                    href="https://www.carfiamoniaco.com.ar" target="_blank" rel="noreferrer">{idioma ? 'Ir al sitio' : 'Go to the website'}</a>
                            </div>
                            <Modal
                                show={showModalCarfi}
                                onHide={() => setShowModalCarfi(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <Image
                                        src='/imagenes/proyectos/carfi1.png'
                                        className='img-fluid mb-3'
                                        alt="" />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalCarfi(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='imagenes/laptop/nameapp.png'
                            className='img-fluid mb-3'
                            alt=""
                            onClick={() => setShowModalNameApp(true)}
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'NameApp es una aplicación que ayuda a encontrar un nombre. Te registras, eliges el género del nombre y la modalidad (Solitario, descarte o coincidencia). Realizado con Front React y Back Laravel (RestFull).' : 'NameApp is an application that helps you find a name. You register, choose the gender of the name, and the mode (Solitary, discard, or match). Developed with Front React and Back Laravel (RestFull).'}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <a type="button" className="btn rounded-pill btn-primary" href="/nameapp"
                                    rel="noopener noreferrer" target="_blank">{idioma ? 'Probar NameApp' : 'Try NameApp'}
                                </a>
                                    <a className="text-dark ms-3 mt-1" href="https://github.com/maxicaprioglio" target="_blank"
                                        rel="noopener noreferrer">
                                        <BsGithub size={30}/>
                                    </a>
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={showModalNameApp}
                        onHide={() => setShowModalNameApp(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <Image
                                src='/imagenes/proyectos/nameapp.png'
                                className='img-fluid mb-3'
                                alt="" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => setShowModalNameApp(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <hr />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='/imagenes/laptop/jdmserv.png'
                            className='img-fluid mb-3'
                            onClick={() => setShowModalJdmserv(true)}
                            alt=""
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'Un proyecto web estático para una empresa de destapaciones. Realizado con HTML, CSS, JS y Bootstrap.' : 'A static web project for a drain cleaning company. Created using HTML, CSS, JS, and Bootstrap.'}
                        </p>
                        <div className="row justify-content-center">
                            <div className="col-11 d-flex justify-content-center">
                                <button className="btn rounded-pill btn-primary me-2" onClick={() => setShowModalJdmserv(true)}>{idioma ? 'Vista previa' : 'Preview'}</button>
                                <a type="button" className="btn rounded-pill btn-primary ms-2" href="https://www.jdmserv.com.ar"
                                    target="_blank" rel="noreferrer">{idioma ? 'Ir al sitio' : 'Go to the website'}</a>
                                <Modal
                                    show={showModalJdmserv}
                                    onHide={() => setShowModalJdmserv(false)}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Body>
                                        <Image
                                            src='/imagenes/proyectos/jdmserv1.png'
                                            className='img-fluid mb-3'
                                            alt="" />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={() => setShowModalJdmserv(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='/imagenes/laptop/catalogo.png'
                            className='img-fluid mb-3'
                            alt=""
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'Un proyecto de catálogo de compras, con su administrador para agregar o sacar productos. Podes ingresar y probar el administrador y ver cómo quedaría tu catálogo de compras. Realizado en Laravel con PHP, HTML, CSS, JS y Bootstrap.' : 'A shopping catalog project with an administrator to add or remove products. You can log in and try the administrator and see how your shopping catalog would look. Built with Laravel using PHP, HTML, CSS, JS, and Bootstrap.'}
                        </p>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <a type="button" className="btn rounded-pill btn-primary" href="/proyecto_catalogo/administrador/productos"
                                    rel="noopener noreferrer" target="_blank">{idioma ? 'Probar el catálogo' : 'Try the catalog'}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <Cotizador idioma={idioma} />
                <hr />
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 imgefecto">
                        <Image
                            src='imagenes/laptop/bukaVu.png'
                            className='img-fluid mb-3'
                            alt="Buka-Vu"
                            fluid
                        />
                    </div>
                    <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                        <p className="text-center m-3">{idioma ? 'Estos son algunos de mis trabajos que realicé desde 2001 hasta 2013, cuando trabajé en castings como coordinador, productor o editor. Además, realicé proyectos independientes de edición, como reels actorales, backstage de marcas de ropa, videos personalizados para cumpleaños o eventos e institucionales.' : 'These are some of my works that I carried out from 2001 to 2013 when I worked in castings as a coordinator, producer, or editor. Additionally, I undertook independent editing projects such as actor reels, backstage footage for clothing brands, customized videos for birthdays or events, and institutional videos.'}</p>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <button className="btn rounded-pill btn-primary me-2" onClick={() => setShowModalVideo(true)}>{idioma ? 'Ver' : 'See'}</button>
                            </div>
                            <Modal
                                show={showModalVideo}
                                onHide={() => setShowModalVideo(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <p className='text-center fs-2'>{idioma ? 'Ver el proyecto de:' : 'See the project of:'}</p>

                                    <div className="row justify-content-center px-5">
                                        <button className="btn rounded-pill btn-outline-secondary m-2" onClick={() => setShowModalBuka(true)}>Backstage</button>
                                        <button className="btn rounded-pill btn-outline-secondary m-2" onClick={() => setShowModalClip(true)}>Video Clip</button>
                                        <button className="btn rounded-pill btn-outline-secondary m-2" onClick={() => setShowModalCasting(true)}>Spot</button>
                                        <button className="btn rounded-pill btn-outline-secondary m-2" onClick={() => setShowModalReel(true)}>Reel</button>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalVideo(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showModalBuka}
                                onHide={() => setShowModalBuka(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <ReactPlayer
                                        url='/imagenes/proyectos/video/buka-vu.mp4'
                                        controls
                                        width='100%'
                                        height='100%'
                                        className='img-fluid mb-3'
                                        alt="" />
                                    <p className='fw-bold'>2007 - Backstage Buka-Vu</p>
                                    <p>{idioma ? 'Proyecto realizado íntegramente por mí (idea, filmación y edición).' : 'Project entirely done by me (idea, filming, and editing).'}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalBuka(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showModalReel}
                                onHide={() => setShowModalReel(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <ReactPlayer
                                        url='/imagenes/proyectos/video/lapadula.mov'
                                        controls
                                        width='100%'
                                        height='100%'
                                        className='img-fluid mb-3'
                                        alt="" />
                                    <p className='fw-bold'>{idioma ? '2008 - Reel actoral' : '2008 - Acting Reel'}</p>
                                    <p>{idioma ? 'Un proyecto para hacer el Reel actoral de los trabajos realizados de Pablo Lapadula.' : 'An acting reel is an edit of works done by the actor Pablo Lapadula.'}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalReel(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showModalClip}
                                onHide={() => setShowModalClip(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <ReactPlayer
                                        url='/imagenes/proyectos/video/disco.mp4'
                                        controls
                                        width='100%'
                                        height='100%'
                                        className='img-fluid mb-3'
                                        alt="" />
                                    <p className='fw-bold'>2009 - Video Clip</p>
                                    <p>{idioma ? 'Proyecto realizado en equipo en producción y realice la edición.' : 'In-team project aimed at aiding in production and carrying out the editing.'}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalClip(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={showModalCasting}
                                onHide={() => setShowModalCasting(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Body>
                                    <ReactPlayer
                                        url='/imagenes/proyectos/video/casting.mp4'
                                        controls
                                        width='100%'
                                        height='100%'
                                        className='img-fluid mb-3'
                                        alt="" />
                                    <p className='fw-bold'>2009 - Casting</p>
                                    <p>{idioma ? 'Un spot de servicios de casting el cual realice la edición.' : 'A casting services spot in which I carried out the editing.'}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => setShowModalCasting(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                                </Modal.Footer>
                            </Modal >
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}


