import { useContext, useState, useEffect } from 'react'
import { Image, Modal, Button } from 'react-bootstrap'
import { IdiomaContext } from '../../../context/IdiomaContext'
import { Tabla } from './Tabla/Tabla'

export const Cotizador = () => {
    const { idioma } = useContext(IdiomaContext)
    const [showModalCotizador, setShowModalCotizador] = useState(false)
    const [valorOficial, setValorOficial] = useState('Cargando...')
    const [valorOficialBuy, setValorOficialBuy] = useState('Cargando...')
    const [valorBlue, setValorBlue] = useState('Cargando...')
    const [valorBlueBuy, setValorBlueBuy] = useState('Cargando...')
    const [valorSolidario, setValorSolidario] = useState('Cargando...')
    const [table, setTable] = useState(false)
    const [arrayBlue, setArrayBlue] = useState([])
    const [arrayDia, setArrayDia] = useState([])
    const [arrayOficial, setArrayOficial] = useState([])

    const saber = () => {
        const fecha = document.getElementById('fecha').value
        let respuesta = idioma ? 'No hay datos' : 'No data'

        fetch(`https://api.bluelytics.com.ar/v2/historical?day=${fecha}`)
            .then(response => response.json())
            .then(data => {
                setValorOficial(data.oficial.value_sell)
                setValorOficialBuy(data.oficial.value_buy)
                setValorBlue(data.blue.value_sell)
                setValorBlueBuy(data.blue.value_buy)
                const fechaFormateada = `${fecha}`
                setValorSolidario(fechaFormateada)
            })
            .catch(() => {
                setValorOficial(respuesta)
                setValorOficialBuy(respuesta)
                setValorBlue(respuesta)
                setValorBlueBuy(respuesta)
                const fechaFormateada = `${fecha}`
                setValorSolidario(fechaFormateada)
            })
    }
    function fetchHistotico(x) {
        fetch(`https://api.bluelytics.com.ar/v2/evolution.json?days=${x}`)
            .then(response => response.json())
            .then(data => {
                let blue = []
                let oficial = []
                let dia = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].source !== 'Oficial') {
                        blue.push(data[i].value_sell)
                    } else {
                        oficial.push(data[i].value_sell)
                    }
                    dia.push(data[i].date)
                }
                let uniqueDia = [...new Set(dia)];
                setArrayBlue(blue.reverse())
                setArrayDia(uniqueDia.reverse())
                setArrayOficial(oficial.reverse())
            })
            .catch(error => {
                console.log(error)
            })
    }
    function tabla() {
        setTable(true)
    }
    const ultimosSem = () => {
        fetchHistotico(14)
        if (!table) { tabla() }
    }
    const ultimosMes = () => {
        fetchHistotico(60)
        if (!table) { tabla() }
    }
    const ultimosAnio = () => {
        fetchHistotico(730)
        if (!table) { tabla() }
    }
    const ultimosTresAnio = () => {
        fetchHistotico(2190)
        if (!table) { tabla() }
    }
    const ultimosCincoAnio = () => {
        fetchHistotico(3650)
        if (!table) { tabla() }
    }

    useEffect(() => {
        fetch('https://api.bluelytics.com.ar/v2/latest')
            .then(response => response.json())
            .then(data => {
                setValorOficial(data.oficial.value_sell)
                setValorOficialBuy(data.oficial.value_buy)
                setValorBlue(data.blue.value_sell)
                setValorBlueBuy(data.blue.value_buy)
                const fechaHoraString = data.last_update
                const fechaHora = new Date(fechaHoraString)
                const año = fechaHora.getFullYear()
                const mes = (fechaHora.getMonth() + 1).toString().padStart(2, "0")
                const dia = fechaHora.getDate().toString().padStart(2, "0")
                const hora = fechaHora.getHours().toString().padStart(2, "0")
                const minutos = fechaHora.getMinutes().toString().padStart(2, "0")
                const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}Hs`
                setValorSolidario(fechaFormateada)
            })
            .catch(error => {
                setValorOficial('No hay datos')
                setValorOficialBuy('No hay datos')
                setValorBlue('No hay datos')
                setValorBlueBuy('No hay datos')
                setValorSolidario('No hay datos')
            })
    }, [showModalCotizador])

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-5 imgefecto">
                <Image
                    src='/imagenes/laptop/cotizador.png'
                    className='img-fluid mb-3'
                    onClick={() => setShowModalCotizador(true)}
                    alt=""
                    fluid
                />
            </div>
            <div className="col-12 col-md-7 align-self-center mb-3 text-center text-md-start">
                <p className="text-center m-3">{idioma ? 'Cotizador de dólar consumiendo datos de una Apis de cotizaciones.' : 'Dollar quote consuming data from a quote Apis.'}
                </p>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <button className="btn rounded-pill btn-primary" onClick={() => setShowModalCotizador(true)}>{idioma ? 'Ver' : 'See'}</button>
                        <Modal
                            show={showModalCotizador}
                            onHide={() => setShowModalCotizador(false)}
                            fullscreen={true}
                        >
                            <Modal.Body>
                                <section className="container text-center">
                                    <h1>{idioma ? 'Cotización del dólar' : 'Dollar quote'}
                                    </h1>
                                    <div className='row justify-content-center'>
                                        <div className="col-12">
                                            <input type="date" id="fecha" name="fecha" />
                                            <button className="btn rounded-pill btn-primary m-2" onClick={saber}>{idioma ? 'Buscar' : 'search'}</button>
                                        </div>
                                    </div>
                                    <table className="table table-borderless --bs-table-bg-transparent">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">{idioma ? 'Venta' : 'Sell'}</th>
                                                <th scope="col">{idioma ? 'Compra' : 'Buy'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{idioma ? 'Oficial' : 'Official'}</th>
                                                <td>{valorOficial}</td>
                                                <td>{valorOficialBuy}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Blue</th>
                                                <td>{valorBlue}</td>
                                                <td>{valorBlueBuy}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="text-end text-secondary">{idioma ? 'Ultima actualización: ' : 'Last update: '}{valorSolidario}</p>
                                    <Button onClick={ultimosSem} className='btn-secondary m-2'>{idioma ? 'Última semana' : 'last week'}</Button>
                                    <Button onClick={ultimosMes} className='btn-secondary m-2'>{idioma ? 'Último mes' : 'last month'}</Button>
                                    <Button onClick={ultimosAnio} className='btn-secondary m-2'>{idioma ? 'Último año' : 'last year'}</Button>
                                    <Button onClick={ultimosTresAnio} className='btn-secondary mx-2'>{idioma ? 'Último 3 años' : 'last 3 year'}</Button>
                                    <Button onClick={ultimosCincoAnio} className='btn-secondary mx-2'>{idioma ? 'Últimos 5 años' : 'last 5 year'}</Button>
                                    {table ? <Tabla dia={arrayDia} valorA={arrayBlue} valorB={arrayOficial} /> : <></>}
                                </section>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setShowModalCotizador(false)}>{idioma ? 'Cerrar' : 'Close'}</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}
